<template>
  <div class="orAccountManagement">机构账号</div>
</template>

<script>

export default {
  name: 'orAccountManagement',
  components: {
  }
}
</script>
<style lang="less" scoped>
.orAccountManagement {
  width: 100%;
  height: 100%;
}
</style>